import React from 'react';
import { Menu, Drawer, Divider, Layout } from 'antd';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import Inst from '../img/inst.png';

const { Header } = Layout;

const HeaderMenu = props => {

    const showDrawer = () => {
        props.setState( {leftmenu: 1} );
    };
    const onClose = () => {
        props.setState( {leftmenu: 0} );
    };
    
    const menulist = {
        'top' : [
            {
                name:'Вход',
                icon:'auth',
                link:'#',
                modal: 'auth'
            },
        ],
        'bottom' : [
            {
                name:'Восстановление',
                icon:'registration',
                link:'#',
                modal: 'forgot'
            },
            {
                name:'Регистрация',
                icon:'registration',
                link:'#',
                modal: 'registration'
            },
            {
                name:'8 (800) 444-48-40',
                icon:'phone',
                link:'tel:88004444840',
                role: ["0","1","2","3"]
                
            },
            {
                name:'Почта',
                icon:'mail',
                link:'mailto:tk.r2.ru@gmail.com',
                role: ["0","1","2","3"]
                
            },
            {
                name:'Instagram',
                icon:'instagram',
                link:'https://www.instagram.com/maket_500/',
                role: ["0","1","2","3"]
                
            },
        ]
    };

    return (
        <React.Fragment>
            <Drawer
                closable={false}
                placement="left"
                onClose={onClose}
                visible={props.state.leftmenu}
            >
                <div className="logomenu" />
                <Menu mode="vertical" className="leftmenu">
                    { menulist.top.length > 0 &&
                        menulist.top.map( (list,index) => <Menu.Item icon={props.icons(list.icon)} key={`${list.name}-${index}`} ><a href={list.link} onClick={() => props.modal(list.modal)}>{list.name}</a></Menu.Item>)
                    }
                    { menulist.bottom.length > 0 &&
                        <React.Fragment>
                            <Divider />
                            {
                                menulist.bottom.map( (list,index) => <Menu.Item icon={props.icons(list.icon)} key={`${list.name}-${index}`} ><a href={list.link} onClick={() => props.modal(list.modal)}>{list.name}</a></Menu.Item>)
                            }
                        </React.Fragment>
                    }
                </Menu>
            </Drawer>
            <Header>
            {
                props.state.menumini ? (
                    <React.Fragment>
                        <span onClick={showDrawer} className="menubutton">
                            <MenuOutlined />
                        </span>
                        <div className="logoblock"><a href="/" className="logomenu2"> </a></div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="logoblock"><a href="/" className="logomenu2"> </a></div>
                        <div className="header-flex">
                            <a href="tel:88004444840" className="header-href header-phone span-gr" style={{marginRight:'70px'}}><span>8 (800) 444-48-40</span></a>
                            <a href="https://www.instagram.com/maket_500/" target="_blank" ><img src={Inst} className="inst-sh" alt="" /></a> 
                            <a href="#auth" onClick={() => props.modal('auth')} ><div className="authbutton">Войти <UserOutlined style={{marginLeft:'10px'}} /></div></a>
                        </div>
                        
                    </React.Fragment>
                    
                )
            }
            </Header>
        </React.Fragment>
    );
}

export default HeaderMenu;

//GLOBAL
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, {Component, Suspense, lazy } from 'react';
import Style from 'style-it';
import { createCanvas } from 'canvas';

//DESIGN
import { Layout, ConfigProvider, notification, Tooltip, message } from 'antd';
import { CreditCardOutlined, HomeOutlined, PlusCircleOutlined, CommentOutlined, ImportOutlined, AppstoreOutlined, GiftOutlined, LoginOutlined, FormOutlined, SettingOutlined, HistoryOutlined, ShoppingOutlined, WhatsAppOutlined, MailOutlined, InstagramOutlined, PhoneOutlined } from '@ant-design/icons';
import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';

//GLOBAL COMPONENTS
import HeaderMenu from './navigation/HeaderMenu';
import NavbarAside from './navigation/NavbarAside';

//STYLE
import './App.css';
import 'antd/dist/antd.css';

//ANTD
const { Content } = Layout;

//PAGES
const MainPage = lazy(() => import('./pages/mainPage/pageMain'));
const AuthPage = lazy(() => import('./pages/authPage/pageAuth'));
const ForgotPage = lazy(() => import('./pages/forgotPage/pageForgot'));
const RegisterPage = lazy(() => import('./pages/registerPage/pageRegister'));
const ExitPage = lazy(() => import('./pages/exitPage/pageExit'));
const HomePage = lazy(() => import('./pages/homePage/pageHome'));
const ProfilePage = lazy(() => import('./pages/profilePage/pageProfile'));
const OrdersPage = lazy(() => import('./pages/ordersPage/pageOrders'));
const OrderPage = lazy(() => import('./pages/orderPage/pageOrder'));
const PaymentsPage = lazy(() => import('./pages/paymentsPage/pagePayments'));
const JobsPage = lazy(() => import('./pages/jobsPage/pageJobs'));
const PromoPage = lazy(() => import('./pages/promoPage/pagePromo'));
const Error404Page = lazy(() => import('./pages/error404Page/pageError404'));

const notif = (type,title, text) => {
  notification[type]({
    message: title,
    description:text,
    placement:'bottomRight',
  });
};

const openRefresh = (v,key) => {
  message.loading({ content: v, key });
};

class App extends Component {

  constructor(props) {
		super(props);

    this.state = {
      auth: 1,
      error: ['',''],
      panel: [],
      modal: '',
      loading: true,
      moreload: true,
      api: 'https://maket500.ru/api/',
      search: '',
      leftmenu: 0,
      menumini: 0,
      orders: [],
      order: [],
      packs: [],
      forgot_code: '',
      payments: [],
      payment : [],
      pack_prices: [],
      jobs: [],
      job: [],
      politic: [],
      packs_comments: [],
      packs_direct: [],
      packs_subscriptions: [],
      order_types: [],
      order_cat: [],
      order_subcat: [],
      order_sizes: [],
      globalclass: 'home',
      refresh: false,
      profile: [],
      globalnotif: [],
      error_form: [],
      captcha: '6LeFl08dAAAAAAmxh1LFUqm-f3acBcG3sE_vEwxW',
      captcha_test: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      dev: false,
      sl_min: 0,
      sl_max: 0,
      sl_search: 0,
      sl_nosearch: 0,
      sl_user: 0,
      promo_list: [],
      promo_view: [],
      sl_canvas: '',
    };
  }

  //* Вызов сразу после рендера
  async componentDidMount() {
    var params = (new URL(document.location)).searchParams; 
    var confirm = "";
    if (this.getCookie('token') !== undefined) {
      await this.login(1,1,this.getCookie('token'));
      switch(window.location.pathname.split('/')[1]) {
        case '':
          //@ Запрос на подтверждение
          confirm = params.get("confirm");
          if (confirm) {
            await this.api({ 'method':'profile_confirm', 'confirm':confirm }, true);
          }
          //@ Откуда пришел
          var from = params.get("f");
          if (from !== '' && from !== null) {
            await this.api({ 'method':'f', 'v':from }, false);
            let baseUrl = window.location.href.split("?")[0];
            window.history.pushState('f', '', baseUrl);
          }
          if(window.location.hash) {
            var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
            switch(hash) {
              case 'politic':
                this.panel('politic');
                break;
              case 'personal':
                this.panel('personal');
                break;
              case 'oferta':
                this.panel('oferta');
                break;
              case 'terms':
                this.panel('terms');
                break;
              case 'auth':
                this.modal('auth');
                break;
              case 'forgot':
                this.modal('forgot');
                break;
              case 'forgot_confirm':
                this.modal('forgot_confirm');
                break;
              case 'registration':
                this.modal('registration');
                break;
              default:
                
                break;
            }
          }
          break;
        case 'profile':
          //@ Подтверждение профиля
          confirm = params.get("confirm");
          if (confirm === 'true') {
            notif('success','Профиль подтверждён','');
            notif('success','Вы получили бонусные деньги на счёт!','');
          }
          break;
        case 'orders':
          await this.api({ 'method':'orders' }, true);
          break;
        case 'payments':
          await this.api({ 'method':'payments' }, true);
          break;
        case 'order':
          await this.api({ 'method':'pack_prices' }, true);
          await this.api({ 'method':'packs' }, true);
          break;
        case 'jobs':
          await this.api({ 'method':'jobs' }, true);
          setInterval(() => 
            this.state.refresh ? (
              this.api({ 'method':'jobs' }, false),
              openRefresh('Получение заказов','refresh')
            ) : (
              console.log('sleep')
            )
          , 30000);
          break;
        case 'promo':
          await this.api({ 'method':'promo_list' }, true);
          break;
        default:
          break;
      }
    } else {
      switch(window.location.pathname.split('/')[1]) {
        case '':
          //@ Подтверждение профиля
          confirm = params.get("confirm");
          if (confirm) {
            await this.api({ 'method':'profile_confirm', 'confirm':confirm }, true);
          }
          //@ Откуда пришел
          from = params.get("f");
          if (from !== '' && from !== null) {
            await this.api({ 'method':'f', 'v':from }, false);
            let baseUrl = window.location.href.split("?")[0];
            window.history.pushState('f', '', baseUrl);
          }
          //@ Проверка на восстановление пароля
          confirm = params.get("forgot");
          if (confirm) {
            await this.api({ 'method':'forgot_check', 'confirm':confirm }, true);
            await this.setState( { forgot_code:confirm } );
          }

          if(window.location.hash) {
            var hash = window.location.hash.substring(1);
            switch(hash) {
              case 'politic':
                this.panel('politic');
                break;
              case 'personal':
                this.panel('personal');
                break;
              case 'oferta':
                this.panel('oferta');
                break;
              case 'terms':
                this.panel('terms');
                break;
              case 'auth':
                this.modal('auth');
                break;
              case 'forgot':
                this.modal('forgot');
                break;
              case 'forgot_confirm':
                this.modal('forgot_confirm');
                break;
              case 'registration':
                this.modal('registration');
                break;
              default:
                
                break;
            }
          }
          break;
        case 'profile':
          confirm = params.get("confirm");
          if (confirm === 'true') {
            notif('success','Профиль подтверждён','');
            notif('success','Вы получили бонусные деньги на счёт!','');
          }
          break;
        case 'auth':
          this.modal('auth');
          break;
        default:
          break;
      }
      await this.setState( { auth:1, loading:false } );
    }
    this.resizewindow()
    window.addEventListener('resize', this.updateDimensions);
  }

  async resizewindow() {
    this.setState({ leftmenu:0 });
    var width = window.innerWidth;
    var status = true;
    if (width > 1000) {
      status = false;
    }
    this.setState({ menumini:status });
  }

  updateDimensions = () => {
    this.resizewindow()
    console.log();
  };

  //* POST запрос
  postRequest(url, data) {
    return fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    })
    .then(response => response.json())
  }

  //* Вход
  async login (l,p,t,r) {
    await this.setState( { auth: 0 } );
    let user = { email: l, pass: p, method: 'auth' };
    if (t) {
      user = { token: t, method: 'auth' };
    }
    
    await this.postRequest(`${this.state.api}`, user)
    .then(async json => {
      var data = json;
      if(data.code) {
        let profile = ( data.profile ) ? data.profile : {};
        
        await this.setState( { profile: profile } );
  
        if (!t) {
          await this.setCookie('token', profile.token);
          window.location.href = '/home';
        }

        await this.setState( { auth:2, loading: false } );

        if (r) {
          await this.setCookie('token', profile.token);
          window.location.href = '/'+r;
        }
      }
      if(data.error) {
        await this.setState( { auth:1, error: data.error } );
        notif('error',`${data.error[0]}`,`${data.error[1].length > 0 ? `${data.error[1]}`:''}`);
        if (data.error[0] === 'Неверный логин или пароль') {
          await this.delCookie('token');
          await this.setState( {auth: 1, error: ['','']} );
        }
      }
    })
    .catch( async err => {
      await this.setState( { auth:1, error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
  }

  //* Получение информации
  async api(v,s) {
    if (s) {
      await this.setState( { loading: true, moreload:true, modal:'' } );
    } else {
      await this.setState({ moreload:true });
    }
    
    v['token'] = this.getCookie('token');
    await this.postRequest(`${this.state.api}`, v)
    .then(async json => {
      var data = json;
      if(data.code) {
        let profile = ( data.profile ) ? data.profile : {};
        let globalnotif = ( data.globalnotif ) ? data.globalnotif: [];

        await this.setState( { profile: profile, globalnotif:globalnotif } );

        var result = [];

        //* Методы
        switch(v['method']) {
          case 'call':
              notif('success','Заявка отправлена','В ближайшее время с Вами свяжутся по указанному номеру телефона');
              break;
            case 'registration':
              var token	= ( data.token ) ? data.token : '';
              notif('success','Вы успешно зарегистрировались!','Теперь вы можете войти в свою учетную запись.');
              await this.login(1,1,token,'order');
              break;
            case 'forgot':
              notif('success','Ожидается подтверждение','Письмо с подтверждением отправлено на вашу почту.');
              break;
            case 'forgot_check':
              await this.modal('forgot_confirm');
              break;
            case 'forgot_confirm':
              notif('success','Пароль обновлён','');
              break;
            case 'profile_update':
              notif('success','Профиль обновлён','');
              break;
            case 'profile_password':
              notif('success','Пароль обновлён','');
              break;
            case 'profile_request':
              notif('success','Ожидается подтверждение','Письмо с подтверждением отправлено на вашу почту.');
              break;
            case 'profile_confirm':
              window.location.href = '/profile?confirm=true';
              break;
            case 'orders':
              result	= ( data.result ) && data.result;
              order_types = ( data.types ) && data.types;
              order_cat = ( data.cat ) && data.cat;
              order_subcat = ( data.subcat ) && data.subcat;
              order_sizes = ( data.sizes ) && data.sizes;
              await this.setState( { orders:result, order_types:order_types, order_cat:order_cat, order_subcat:order_subcat, order_sizes:order_sizes } );
              break;
            case 'order_add':
              notif('success','Заказ создан','');
              window.location.href = '/orders';
              break;
            case 'order_pay':
              notif('success','Заказ оплачен','');
              window.location.href = '/orders';
              break;
            case 'order':
                result	= ( data.result ) && data.result;
              await this.setState( { order:result } );
              break;
            case 'order_update':
              notif('success','Заказ обновлён','');
              await this.api({ 'method':'order', 'id': data.result}, false);
              await this.api({ 'method':'orders'}, false);
              break;
            case 'packs':
              result	= ( data.result ) && data.result;
              var order_types = ( data.types ) && data.types;
              var order_cat = ( data.cat ) && data.cat;
              var order_subcat = ( data.subcat ) && data.subcat;
              var order_sizes = ( data.sizes ) && data.sizes;
              await this.setState( { packs:result, order_types:order_types, order_cat:order_cat, order_subcat:order_subcat, order_sizes:order_sizes } );
              break;
            case 'pack_prices':
              result	= ( data.result ) && data.result;
              await this.setState( { pack_prices:result } );
              break;
            case 'payments':
              result	= ( data.result ) && data.result;
              await this.setState( { payments:result } );
              break;
            case 'payment_add':
              notif('success','Ссылка на оплату получена!','');
              result	= ( data.result ) && data.result;
              await this.setState( { payment:result, modal:'pay' } );
              break;
            case 'jobs':
              result	= ( data.result ) && data.result;
              order_types = ( data.types ) && data.types;
              order_cat = ( data.cat ) && data.cat;
              order_subcat = ( data.subcat ) && data.subcat;
              order_sizes = ( data.sizes ) && data.sizes;
              await this.setState( { jobs:result, order_types:order_types, order_cat:order_cat, order_subcat:order_subcat, order_sizes:order_sizes } );
              break;
            case 'job':
              result	= ( data.result ) && data.result;
              await this.setState( { job:result } );
              break;
            case 'job_update':
              notif('success','Заказ обновлён!','');
              await this.api({ 'method':'job', 'id': data.result}, false);
              await this.api({ 'method':'jobs'}, false);
              break;
            case 'packs_comments':
              result	= ( data.result ) && data.result;
              await this.setState( { packs_comments:result } );
              break;
            case 'packs_subscriptions':
              result	= ( data.result ) && data.result;
              await this.setState( { packs_subscriptions:result } );
              break;
            case 'packs_direct':
              result	= ( data.result ) && data.result;
              await this.setState( { packs_direct:result } );
              break;
            case 'promo_add':
              notif('success','Промокод добавлен!','');
              await this.api({ 'method':'promo_list'}, false);
              break;
            case 'promo_upd':
              notif('success','Промокод обновлен!','');
              await this.api({ 'method':'promo_list'}, false);
              break;
            case 'promo_view':
              result	= ( data.result ) && data.result;
              await this.setState( { promo_view:result } );
              break;
            case 'promo_use':
              notif('success','Промокод активирован!','');
              await this.api({ 'method':'payments'}, false);
              break;
            case 'promo_list':
              result	= ( data.result ) && data.result;
              await this.setState( { promo_list:result } );
              break;
          default: break;
        }
      }
      if(data.error) {
        await this.setState( { error: data.error } );
        notif('error',`${data.error[0]}`,`${data.error[1].length > 0 ? `${data.error[1]}`:''}`);
        if (data.error[0] === 'Неверный логин или пароль') {
          await this.delCookie('token');
          await this.setState( {auth: 1, error: ['','']} );
        }
      }
    })
    .catch( async err => {
      await this.setState( { error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
    if (s) {
      await this.setState( { loading: false, moreload: false } );
    } else {
      await this.setState({ moreload:false });
    }
  }

  icons(n) {
    switch(n) {
      case 'home': return <HomeOutlined />;
      case 'auth': return <LoginOutlined />;
      case 'registration': return <FormOutlined />;
      case 'profile': return <SettingOutlined />;
      case 'orders': return <HistoryOutlined />;
      case 'order': return <PlusCircleOutlined />;
      case 'payments': return <CreditCardOutlined />;
      case 'price': return <ShoppingOutlined />;
      case 'jobs': return <CommentOutlined />;
      case 'exit': return <ImportOutlined />;
      case 'whatsapp': return <WhatsAppOutlined />;
      case 'mail': return <MailOutlined />;
      case 'instagram': return <InstagramOutlined />;
      case 'phone': return <PhoneOutlined />;
      case 'promo': return <GiftOutlined />;
      default: return <AppstoreOutlined />;
    }
  }

  randomInteger(min, max) {
    let rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
  }

  async randomslider() {
    var tsl_min = this.randomInteger(1, 40);
    var tsl_max = this.randomInteger(60, 100);
    var tsl_search = this.randomInteger(tsl_min+1, tsl_max);
    var tsl_nosearch = this.randomInteger(tsl_min+1, tsl_max);
    var good = 0;
    while(good < 1) {
      if (tsl_search === tsl_nosearch) {
        tsl_nosearch = this.randomInteger(tsl_min+1, tsl_max);
      } else {
        good = 1;
      }
    }
    await this.setState({ sl_min: tsl_min, sl_max: tsl_max, sl_search: tsl_search, sl_user: tsl_min, sl_nosearch: tsl_nosearch });
    await this.updateCanvas(tsl_search);
  }
  
  //* Модальные окна
  async modal(t) {
    if (t === "") {
      window.location.hash = '';
    }
    switch(t) {
      case 'registration':
        this.randomslider();
      break;
      case 'forgot':
        this.randomslider();
      break;
      case 'feedback':
        this.randomslider();
      break;
    default: break;
    }
    await this.setState( { modal:t, leftmenu:0 } );
  }

   //* Модальные окна
   async panel(t) {
    var panels = this.state.panel;
    if (panels.indexOf( t ) !== -1) {
      window.location.hash = '';
      var index = panels.indexOf(t);
      if (index > -1) panels.splice(index, 1);
    } else {
      await panels.push(t);
    }
    await this.setState( { panel:panels, leftmenu:0 } );
  }

  //* Добавление куки
  setCookie(name, value, options = {}) {
    options = { path: '/', ...options };
    if (options.expires instanceof Date) { options.expires = options.expires.toUTCString(); }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) { updatedCookie += "=" + optionValue; }
    }
    document.cookie = updatedCookie;
  }

  //* Получение куки
  getCookie(name) {
    let matches = document.cookie.match(new RegExp( "(?:^|; )" + name.replace(/([$?*|{}\]\\^])/g, '\\$1') + "=([^;]*)" ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  //* Удаление куки
  delCookie (name) { 
    this.setCookie(name, "", { 'max-age': -1 }) 
  };

  //* Дата
  lasttime(time,empty,type) {
    let newtime 	= new Date();
		let oldtime 	= new Date();
		let notiftime 	= new Date( time * 1000 );
		newtime.setMinutes( newtime.getMinutes() );
		oldtime.setMinutes( newtime.getMinutes() - 1440 );
		notiftime.setMinutes( notiftime.getMinutes());
		let newdate 	= Math.floor( newtime.getTime()/1000 );
		let notifdate 	= Math.floor( notiftime.getTime()/1000 );
		let diff 		= newdate-notifdate;
		let monthlist 	= { "00":"янв ", "01":"фев ", "02":"мар ", "03":"апр ", "04":"мая ", "05":"июн ", "06":"июл ", "07":"авг ", "08":"сен ", "09":"окт ", "10":"ноя ", "11":"дек " };
    let monthliststrong 	= { "00":"01", "01":"02", "02":"03", "03":"04", "04":"05", "05":"06", "06":"07", "07":"08", "08":"09", "09":"10", "10":"11", "11":"12" };
		let info 		= '';
		if ( diff < 60 ) 	{ info = Math.floor( diff ) + this.decnum( Math.floor( diff ),[ ' секунду ',' секунды ',' секунд ' ] ) + " назад "; }
		if ( diff > 60 ) 	{ info = Math.floor( diff/60 ) + this.decnum( Math.floor( diff/60 ),[ ' минуту ',' минуты ',' минут '] ) + " назад "; }
		if ( diff > 1800 ) 	{ info = "полчаса назад "; }
		if ( diff > 3600 ) 	{ info = "час назад "; }
		if ( diff > 7200 ) 	{ info = "2 часа назад "; }
		if ( diff > 10800 ) { info = "3 часа назад "; }
		if ( diff > 14400 ) {
			if ( ( '0' + notiftime.getDate() ).slice( -2 ) === ( '0' + newtime.getDate() ).slice( -2 ) ) {
				info = "сегодня в " + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + " "; 
			} else {
				info = "вчера в " + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + " ";
			}
		}
		if ( diff > 24*3600 ) {
			if ( ('0' + notiftime.getDate()).slice(-2) === ('0' + oldtime.getDate()).slice(-2) ) {
				info = "вчера в " + ('0' + notiftime.getHours()).slice(-2) + ':' + ('0' + notiftime.getMinutes()).slice(-2) + " "; 
			} else {
				info = ('0' + notiftime.getDate()).slice(-2) + ' ' + monthlist[('0' + notiftime.getMonth()).slice(-2)] + ' в ' + ('0' + notiftime.getHours()).slice(-2) + ':' + ('0' + notiftime.getMinutes()).slice(-2) + ' ';
			}
		}
		if ( diff > 2*24*3600 ) { info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' '; }
		let diffrev = notifdate-newdate;
		if ( diff === 0 ) 	{ info = "только что "; }
		if (diffrev > 0) { info = info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' '; }
		let full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' ';
		switch (type) {
      case 'last':
        info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        break;
      case 'mini':
        info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        break;
      case 'full':
        info = full;
        break;
      case 'strong':
        info = ( '0' + notiftime.getDate() ).slice( -2 ) + '.' + monthliststrong[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + '.' + notiftime.getFullYear() + ' ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' ';
        break;
      default: break;
    }
    if (time === '0') {
      info = full = empty;
    }
    return( <Tooltip title={full}>{info}</Tooltip> );
  }

  decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

  stt(value) {
    return <div className="stt">
            <Style>
                {`
                  .stt:before {
                    content: "${value}";
                  }
                `}
              </Style>
            </div>;
  }

  updateCanvas(text) {
    const canvas = createCanvas(50, 50);
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = '20px Arial';
    ctx.fillText(text, 5, 30);
    this.setState({ sl_canvas:ctx.canvas.toDataURL() });
  }

  render() {

    return (
      <ConfigProvider locale={locale}>
        <Layout style={{ minHeight: "100vh" }} className={`page-${window.location.pathname.split('/')[1]}`}>
          { this.state.auth === 2 && <NavbarAside state={this.state} setState={this.setState.bind(this)} icons={this.icons} panel={this.panel} modal={this.modal.bind(this)} /> }
          { this.state.auth === 2 && <div class="floattask" style={{display:'none'}}><span></span></div> }
          <Layout className="lglobal">
            { this.state.auth === 1 && <HeaderMenu state={this.state} setState={this.setState.bind(this)} icons={this.icons} panel={this.panel} modal={this.modal.bind(this)} /> }
            <Content>
              <Router>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    
                    <Route exact path="/" render={ (()=>(
                      <MainPage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} api={this.api.bind(this)} modal={this.modal.bind(this)} login={this.login.bind(this)} randomslider={this.randomslider.bind(this)} stt={this.stt} />
                    )) } />

                    <Route path='/auth' render={(()=>(
                      <AuthPage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} login={this.login.bind(this)} />
                    ))}  />

                    <Route path='/forgot' render={(()=>(
                      <ForgotPage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} api={this.api.bind(this)} />
                    ))}  />

                    <Route path='/registration' render={(()=>(
                      <RegisterPage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} api={this.api.bind(this)} />
                    ))} />

                    <Route path='/home' render={(()=>(
                      <HomePage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} api={this.api.bind(this)} />
                    ))} />

                    <Route path='/profile' render={(()=>(
                      <ProfilePage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} api={this.api.bind(this)} />
                    ))} />

                    <Route path='/orders' render={(()=>(
                      <OrdersPage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} lasttime={this.lasttime.bind(this)} api={this.api.bind(this)} decnum={this.decnum} />
                    ))} />

                    <Route path='/order' render={(()=>(
                      <OrderPage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} api={this.api.bind(this)} decnum={this.decnum} />
                    ))} />

                    <Route path='/payments/:pay_cat?' render={(()=>(
                      <PaymentsPage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} lasttime={this.lasttime.bind(this)} api={this.api.bind(this)} />
                    ))} />

                    <Route path='/jobs' render={(()=>(
                      <JobsPage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} lasttime={this.lasttime.bind(this)} api={this.api.bind(this)} decnum={this.decnum} />
                    ))} />

                    <Route path='/promo' render={(()=>(
                      <PromoPage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} lasttime={this.lasttime.bind(this)} api={this.api.bind(this)} decnum={this.decnum} modal={this.modal.bind(this)} />
                    ))} />

                    <Route path='/exit' render={(()=>(
                      <ExitPage state={this.state} setState={this.setState.bind(this)} notif={notif.bind(this)} panel={this.panel} delCookie={this.delCookie.bind(this)} />
                    ))} />

                    <Route render={(()=>(
                      <Error404Page />
                    ))} />
                  </Switch>
                </Suspense>
              </Router>
            </Content>
          </Layout>
        </Layout>
        <Style>
          {`
            .sb_body:before {
              position: absolute;
              content: "${this.state.sl_nosearch}";
              background: url(${this.state.sl_canvas}) no-repeat;
              background-position: center;
              left: 0;
              top: 0;
              text-align: center;
              font-size: 20px;
              font-family: 'Montserrat-SemiBold';
              color: transparent;
              width: 80px;
              height: 52px;
              margin: auto;
              padding: 10px 20px;
              border-radius: 50px;
            }
          `}
          </Style>
      </ConfigProvider>
    );
  }
}


export default hot(App);

import React from 'react';
import { Menu, Drawer, Divider, Layout, PageHeader, Avatar, Dropdown, Space } from 'antd';
import { MenuOutlined, UserOutlined, ImportOutlined } from '@ant-design/icons';
import Inst from '../img/inst.png';

const { Header } = Layout;

const usermenu = props => {
    return (
        <Menu>
          <Menu.Item key="-2" icon={<UserOutlined />}>
          <a href="/profile">Профиль</a>
          </Menu.Item>
          <Menu.Item key="0" icon={<ImportOutlined />}>
            <a href="/exit">Выход</a>
          </Menu.Item>
        </Menu>
      )
};

const NavbarAside = props => {
    
    const showDrawer = () => {
        props.setState( {leftmenu: 1} );
    };
    const onClose = () => {
        props.setState( {leftmenu: 0} );
    };

    const menulist = {
        'top' : [
            {
                name:'Главная',
                icon:'home',
                link:'/home',
                role: ["0","1","2","3"]
            },
            {
                name:'Профиль',
                icon:'profile',
                link:'/profile',
                role: ["0","1","2","3"]
            },
            {
                name:'Новый заказ',
                icon:'order',
                link:'/order',
                role: ["0","1","2","3"]
            },
            {
                name:'Мои заказы',
                icon:'orders',
                link:'/orders',
                role: ["0","1","2","3"]
            },
            {
                name:'Пополнить баланс',
                icon:'payments',
                link:'/payments',
                role: ["0","1","2","3"]
            }
        ],
        'bottom' : [
            {
                name:'8 (800) 444-48-40',
                icon:'phone',
                link:'tel:88004444840',
                role: ["0","1","2","3"]
                
            },
            {
                name:'Почта',
                icon:'mail',
                link:'mailto:tk.r2.ru@gmail.com',
                role: ["0","1","2","3"]
                
            },
            {
                name:'Instagram',
                icon:'instagram',
                link:'https://www.instagram.com/maket_500/',
                role: ["0","1","2","3"]
                
            },
            {
                name:'Вернуться на сайт',
                icon:'home',
                link:'/',
                role: ["0","1","2","3"]
                
            },
            {
                name:'Заказы',
                icon:'jobs',
                link:'/jobs',
                role: ["1","2"]
            },{
                name:'Промокоды',
                icon:'promo',
                link:'/promo',
                role: ["1","2"]
            },{
                name:'Выход',
                icon:'exit',
                link:'/exit',
                role: ["0","1","2","3"]
            },
        ]
    };

    return (
        <React.Fragment>
            <span onClick={showDrawer} className="menubutton">
                <MenuOutlined />
            </span>
            <Drawer
                width={350}
                maskStyle={{animation:'unset',backgroundColor:'transparent'}}
                closable={false}
                placement="left"
                onClose={onClose}
                visible={props.state.leftmenu}
            >
                <div className="logomenu" />
                <div className="profile_name">{props.state.profile.email}</div>
                <div className="profile_count" style={{margin:'10px auto auto auto'}} onClick={() => window.location.href = '/payments'}>{`Баланс: ${props.state.profile.balance} ₽`}</div>
                <Menu mode="vertical" className="leftmenu">
                    { menulist.top.length > 0 &&
                        menulist.top.map( (list,index) => 
                            list.role.indexOf( props.state.profile.type ) !== -1 &&
                                <Menu.Item className={'/'+window.location.pathname.split('/')[1] === list.link ? 'menu_active' : null} icon={props.icons(list.icon)} key={`${list.name}-${index}`} ><a href={list.link}>{list.name}</a></Menu.Item>
                        )
                    }
                    { menulist.bottom.length > 0 &&
                        <React.Fragment>
                            <Divider/>
                            {
                                menulist.bottom.map( (list,index) => 
                                    list.role.indexOf( props.state.profile.type ) !== -1 &&
                                        <Menu.Item className={'/'+window.location.pathname.split('/')[1] === list.link ? 'menu_active' : null} icon={props.icons(list.icon)} key={`${list.name}-${index}`} ><a href={list.link}>{list.name}</a></Menu.Item>
                                )
                            }
                        </React.Fragment>
                    }
                </Menu>
                {props.state.profile.active === "0" ? <div className="profile_gift" onClick={() => window.location.href = '/profile'}>Получить подарок</div> : null }
            </Drawer>
            <Header className="auth_menu">
                {
                    props.state.menumini ? (
                        <React.Fragment>
                            <span onClick={showDrawer} className="menubutton">
                                <MenuOutlined />
                            </span>
                            <div className="logoblock"><a href="/" className="logomenu2"> </a></div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="logoblock"><a href="/" className="logomenu2"> </a></div>
                            <div className="header-flex">
                                <a href="tel:88004444840" className="header-href header-phone span-gr" style={{marginRight:'70px'}}><span>8 (800) 444-48-40</span></a>
                                <a href="https://www.instagram.com/maket_500/" target="_blank"><img src={Inst} alt="" className="inst-sh" /></a> 
                                <a style={{marginRight:'15px'}} href="/payments" ><button className="authbutton">{`Баланс: ${props.state.profile.balance} ₽`}</button></a>
                            </div>
                        </React.Fragment>
                    )
                }
            </Header>
        </React.Fragment>
    )
};

export default NavbarAside;